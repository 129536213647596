@import "~org/variables";
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

.product-tile {
    position: relative;

    .brand-span {
        line-height: normal;
    }

    .image-container__link {
        background: $gray-100;

        .tile-image {
            mix-blend-mode: multiply;
        }
    }

    .product-brand-name {
        .brand-span {
            font-family: $font-avenir-next-regular;
            color: $dark-brand-grey;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: $font-size-xs;
            min-height: rem(14px);
            display: block;
        }
    }

    .product-name-value {
        margin-top: rem(6px);
        font-family: $font-avenir-next-regular;
        color: $dark-brand-grey;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        white-space: wrap;
        font-size: $font-size-body;
        line-height: rem(21px);
        min-height: rem(41px);
    }

    .tile-body {
        margin-bottom: 0;

        .price {
            margin-top: 0;
        }

        .product-info {
            min-height: auto;
        }
    }

    .small-standalone-link {
        color: $body-color;
        font-weight: $font-weight-normal;

        &:hover {
            color: $slate;
        }
    }

    .no-hover-effect {
        color: inherit !important;
    }
}
